import React, { ReactElement } from 'react'

import Layout from '@/components/layout'

function Page(): ReactElement {
  return (
    <Layout>
      <h1> Hva er skuldersmerter?</h1>
      <p>
        Skuldersmerter er når en har smerter som sitter i eller rundt skulderen. Med skuldersmerter følger ofte en
        følelse av å ha spente muskler i nakke eller smerte i nakken. Det er flere strukturer i skulderregionen som kan
        være smertegenererende (spente muskler, betent slimsekk, slitasje i skulderledd, betent leddkapsel/«frozen
        shoulder»), men fellesnevneren er at skulderen som regel er overbelastet.
      </p>

      <p>
        Kiropraktor Akutten Stavanger/Sandnes har god erfaring med behandling av skulder. Kiropraktoren har
        videreutdanning spesifikt i behandling av skulder. Med skulderproblemer er mange faktorer viktig å se på.
        Funksjonen av ledd i nakke, brystrygg, skulder og albue er viktig. Muskelfunksjon i nakke, brystrygg, rundt
        skulderblad og skulder er også viktig. Til og med om man «stresspuster» og bruker for mye musklene i nakken til
        pusting i stedet for mellomgulvet vil kunne bidra mye til problemer i skulderen. Hver behandling varer fra 20-30
        minutt.
      </p>
    </Layout>
  )
}

export default Page
